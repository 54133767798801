<template>
    <div>
        <div class="right-menu shipping-right">
            <subHeader pageName="PAGE DETAILS" />

            <tutorialsAction />

            <div class="db_top">
                <div class="db-container">

                    <moduleStats/>

                    <!-- search block starts here -->
                    <div class="formOptionsBox">
                        <div class="searchOptionsWrap">
                            <div class="searchBlock" id="page-details-search">
                                <form @submit.prevent="">
                                    <div class="searchIconWrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.491 17.49">
                                            <path id="Path_10" data-name="Path 10" d="M16,17.49h0L11,12.5v-.79l-.27-.28a6.512,6.512,0,1,1,.7-.7l.28.27h.79l4.99,5L16,17.489ZM6.5,2A4.5,4.5,0,1,0,11,6.5,4.505,4.505,0,0,0,6.5,2Z" fill="#848484"/>
                                        </svg>
                                    </div>
                                    <input
                                        type="text"
                                        name="search"
                                        id="search"
                                        ref="searchInput"
                                        placeholder="Search"
                                        v-model="searchedItem"
                                        @input="searchPageDetail()"
                                        :disabled="tourIsActive"
                                    />
                                </form>
                            </div>
                            <div class="filterShowTabsRow" v-if="searchedItem.length > 0">
                                <div class="filterShowTabsListSearchedQuery">
                                    <p>Search results for "{{ searchedItem.length > 20 ? searchedItem.substr(0, 20) + "..." : searchedItem }}".</p>
                                </div>
                                <div class="filterShowTabsResetBtn">
                                    <button @click.prevent="clearSearch">Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- search block ends here -->

                    <!-- data table starts here -->
                    <div class="tableMainWrapBox" style="margin-top: 25px;position: relative;" id="preview-record">
                        <div class="tableScrollWrap" ref="pageDetailsTable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Image">Image</p>
                                                </div>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Title">Title</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Description">Description</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Name">Name</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Slug">Slug</p>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="pageDetailsLoading">
                                    <tr v-for="i in 5" :key="i">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmDataTableImgBox">
                                                    <vue-skeleton-loader
                                                        type="circle"
                                                        :width="36"
                                                        :height="36"
                                                        animation="fade"
                                                    />
                                                </div> 
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-if="pageDetailsList && pageDetailsList.length == 0">
                                        <td colspan="6" style="text-align:center"><p class="mb-0">No Page Details Available</p></td>
                                    </tr>
                                    <tr v-for="(page, index) in pageDetailsList" :key="index">

                                        <!-- page image block starts here -->
                                        <td @click.prevent="previewPageDetails(page,index,'solid',$event)">
                                            <div class="ctmCheckWrap" data-open="true">
                                                <div class="ctmDataTableImgBox" data-open="true">
                                                    <img 
                                                        :src="generateUrl(page) ? generateUrl(page) : '/images/no_image.png'" 
                                                        alt="" 
                                                        data-open="true"
                                                        @error="handleImageError"
                                                    >
                                                </div>
                                            </div>
                                        </td>
                                        <!-- page image block ends here -->

                                        <!-- page title block starts here -->
                                        <td class="mainTableTitle" @click="previewPageDetails(page,index,'input',$event)">
                                            <div class="editFieldTitle" data-open="true" id="page-details-title-field-3">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write a page title"
                                                    v-model="page.title" 
                                                    @focus="pageDetailsSelection(page)" 
                                                    @blur="handleOTGUpdate(page,index,'title')" 
                                                    :disabled="tourIsActive"
                                                    data-open="false"
                                                >
                                            </div>
                                        </td>
                                        <!-- page title block ends here -->

                                        <!-- page description block starts here -->
                                        <td class="mainTableTitle" @click="previewPageDetails(page,index,'input',$event)">
                                            <div class="editFieldTitle" data-open="true" id="page-details-description-field-2">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write a page description"
                                                    v-model="page.description" 
                                                    @focus="pageDetailsSelection(page)" 
                                                    @blur="handleOTGUpdate(page,index,'description')" 
                                                    :disabled="tourIsActive"
                                                    data-open="false"
                                                >
                                            </div>
                                        </td>
                                        <!-- page description block ends here -->

                                        <!-- page name block starts here -->
                                        <td @click.prevent="previewPageDetails(page,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <p data-open="true">{{ page.page_name }}</p>
                                            </div>
                                        </td>
                                        <!-- page name block ends here -->

                                        <!-- page slug block starts here -->
                                        <td @click.prevent="previewPageDetails(page,index,'solid',$event)">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <p data-open="true">{{ page.page_slug }}</p>
                                            </div>
                                        </td>
                                        <!-- page slug block ends here -->

                                    </tr>

                                    <!-- load more block starts here -->
                                    <tr v-if="isPending">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmDataTableImgBox">
                                                    <vue-skeleton-loader
                                                        type="circle"
                                                        :width="36"
                                                        :height="36"
                                                        animation="fade"
                                                    />
                                                </div> 
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="200"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- load more block starts here -->

                                </tbody>
                            </table>
                        </div>

                        <!-- update page details form starts here -->
                        <div class="productSlideBox" ref="viewInputForm" :class="previewForm.id ? 'activeRow': ''" >
                            <div class="tableActionBtns" id="page-details-preview">
                                <div class="productTableSideBar">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <div class="viewOrderTitle" id="page-details-title-field-2">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write Your Page Title" 
                                                    v-model="previewForm.title"
                                                    :disabled="tourIsActive"
                                                >
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="orderViewNav">
                                                <ul>
                                                    <el-tooltip class="box-item" effect="dark" content="Expand" placement="top-start">
                                                        <li class="sm-extand-hide">
                                                            <button @click.prevent="expand" :disabled="tourIsActive" id="expand-form">
                                                                <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
                                                                    <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                                                        <path d="m16.5 5.5v-4.978l-5.5.014"/>
                                                                        <path d="m16.5.522-6 5.907"/>
                                                                        <path d="m11 16.521 5.5.002-.013-5.5"/>
                                                                        <path d="m16.5 16.429-6-5.907"/>
                                                                        <path d="m.5 5.5v-5h5.5"/>
                                                                        <path d="m6.5 6.429-6-5.907"/>
                                                                        <path d="m6 16.516-5.5.007v-5.023"/>
                                                                        <path d="m6.5 10.5-6 6"/>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                    <el-tooltip class="box-item" effect="dark" content="Close" placement="top-start">
                                                        <li>
                                                            <button @click.prevent="closePreview" id="closeSide" :disabled="tourIsActive">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="29.446" height="33.409" viewBox="0 0 29.446 33.409">
                                                                    <g id="Group_20906" data-name="Group 20906" transform="translate(362.041 -2185.5)">
                                                                        <path id="Path_57522" data-name="Path 57522" d="M-361.041,2186.648v31.409" transform="translate(0 -0.148)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                        <g id="Group_20905" data-name="Group 20905" transform="translate(0 -0.5)">
                                                                            <path id="Path_57523" data-name="Path 57523" d="M-354.018,2202.5h20.265" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                            <path id="Path_57524" data-name="Path 57524" d="M-342.6,2193.747l8,9.15-8,8.85" transform="translate(1 -0.247)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sectionTabsLink">
                                    <ul onclick="window.myFunction(event)">
                                        <li v-if="!tourIsActive">
                                            <a class="active" id="page-details-navbtn" href="#pageDetails">Page Details</a>
                                        </li>
                                    </ul>
                                    <div class="updateBtnBox" v-if="pageDetailsIsSaving">
                                        <loaderBtn/>
                                    </div>
                                    <div class="updateBtnBox" id="save-page-details-btn" v-else>
                                        <button 
                                            form="page-details-information" 
                                            button="submit" 
                                            @click="validate()" 
                                            :disabled="tourIsActive"
                                        >
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <ValidationObserver ref="pageDetailsFormObserver">
                                <div class="productTableInfoBlock">
                                    <div class="productTableInfoWhiteBox" id="pageDetailsForm">
                                        <form id="page-details-information" @submit.prevent="validate()">
                                            <div class="tableFromFieldBox">
                                                <label for="page-details-name-field">Page Name</label>
                                                <div class="tableFromFieldItem">
                                                    <input 
                                                        type="text" 
                                                        id="page-details-name-field" 
                                                        name="page-details-name-field" 
                                                        class="form-control" 
                                                        placeholder="Page name" 
                                                        v-model="previewForm.page_name"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <label for="page-details-slug-field">Page Slug</label>
                                                <div class="tableFromFieldItem">
                                                    <input 
                                                        type="text" 
                                                        id="page-details-slug-field" 
                                                        name="page-details-slug-field" 
                                                        class="form-control" 
                                                        placeholder="Page slug" 
                                                        v-model="previewForm.page_slug"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <label for="page-details-title-field-1">Page Title<span>*</span></label>
                                                <ValidationProvider name="title" rules="required|max:70" v-slot="{ errors }">
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="text" 
                                                            id="page-details-title-field-1" 
                                                            name="page-details-title-field-1" 
                                                            class="form-control" 
                                                            placeholder="Page title" 
                                                            v-model="previewForm.title"
                                                            :disabled="tourIsActive"
                                                        />
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <label for="page-details-description-field-1">Page Description<span>*</span></label>
                                                <ValidationProvider name="description" rules="required|max:250" v-slot="{ errors }">
                                                    <div class="tableFromFieldItem">
                                                        <textarea 
                                                            name="page-details-description-field-1" 
                                                            id="page-details-description-field-1" 
                                                            placeholder="Page description" 
                                                            v-model="previewForm.description"
                                                            :disabled="tourIsActive"
                                                        ></textarea>
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox">
                                                <label for="pageDescription">Page Image<span>*</span></label>
                                                <div class="imgUploaderTxt">
                                                    <input 
                                                        type="file" 
                                                        ref="previewFileInput" 
                                                        @change.prevent="updateFormImageChangeHandle" 
                                                        id="feature_img1" 
                                                        accept="image/png,image/jpg,image/jpeg,image/webp" 
                                                        hidden 
                                                        :disabled="tourIsActive"
                                                    >
                                                    <label for="feature_img1">
                                                        <div class="category-tab-img" id="page-details-image">
                                                            <img 
                                                                :src="previewForm.image_src" 
                                                                alt="" 
                                                                v-if="previewForm.image"
                                                            />
                                                            <img 
                                                                :src="previewForm.image_src ? base_image_url + settings.store_id + '/page_details/' + previewForm.image_src : '/images/no_image.png' " 
                                                                alt=""
                                                                @error="handleImageError" 
                                                                v-else
                                                            />
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                        <!-- update page details form ends here -->

                    </div>
                    <!-- data table ends here -->

                </div>
            </div>

        </div>

        <!--vue tour-->
        <v-tour name="pageDetailsTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

    </div>
</template>

<script>
import { MessageBox, Message, Loading } from "element-ui";
import _ from "lodash";
import subHeader from "@/components/subHeader.vue";
import moduleStats from "@/components/moduleStats.vue";
import { mapGetters } from "vuex";
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";

export default {
    metaInfo() {
        return {
            title: 'SEO | Page Details | YeetCommerce',
        };
    },
    data(){
        return {
            base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,

            //search start
            searchedItem:'',
            //search end

            //page details start
            previewForm:{
                id:"",
                page_name:"",
                page_slug:"",
                title:"",
                description:"",
                image: null,
                image_src: null,
            },
            defaultPreviewForm:{
                id:"",
                page_name:"",
                page_slug:"",
                title:"",
                description:"",
                image: null,
                image_src: "",
            },
            tempPageDetails:{
                id:"",
                page_name:"",
                page_slug:"",
                title:"",
                description:"",
                image: null,
                image_src: "",
            },
            tempPreviewPageDetails:{
                id:"",
                page_name:"",
                page_slug:"",
                title:"",
                description:"",
                image: null,
                image_src: "",
            },
            pageDetailsLoading:false,
            pageDetailsList: [],
            pageDetailsSaved:false,
            pageDetailsIsSaving:false,
            //page details end

            //checked items start
            checkedItems:[],
            timer: undefined,
            isMasterSearched:false,
            imageValidations:{
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            },
            //checked items end

            //vue-tour
            tourOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip Tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks: {
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                //search bar step
                {
                    target: '#page-details-search',
                    content: `<strong>Search Page Details</strong><br>It enables you to search specific page details.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#preview-record',
                    content: `<strong>Page Details Table</strong><br>You can preview and edit the available page details by clicking on each individual record present in the table.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'top'
                    }
                },
                {
                    target: '#page-details-preview',
                    content: `<strong>Page Details Form</strong><br>This form can be used to add details about the page and save them.`,
                    params: {
                        highlight: false,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#page-details-name-field',
                    content: `<strong>Page Name</strong><br>Page name can be viewed using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#page-details-slug-field',
                    content: `<strong>Page Slug</strong><br>Page slug can be viewed using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#page-details-title-field-1',
                    content: `<strong>Page Title</strong><br>Page title can be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#page-details-description-field-1',
                    content: `<strong>Page Description</strong><br>Page description can be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#page-details-image',
                    content: `<strong>Page Image</strong><br>It enables you to add image for the page.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#page-details-title-field-3',
                    content: `<strong>Page Title</strong><br>Page title can also be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#page-details-description-field-2',
                    content: `<strong>Page Description</strong><br>Page description can also be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#page-details-title-field-2',
                    content: `<strong>Page Title</strong><br>Page title can also be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#expand-form',
                    content: `<strong>Expand Form</strong><br>Form can be expanded or collapsed by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#closeSide',
                    content: `<strong>Close Form</strong><br>Form can be closed using this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#save-page-details-btn',
                    content: `<strong>Save Page Details</strong><br>Added page details can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
            ],
            tourIsActive:false
        }
    },
    components:{
        subHeader,
        tutorialsAction,
        loaderBtn,
        moduleStats
    },
    computed:{
        ...mapGetters({
            settings: "settings_module/settings",
            PageDetails: "page_details_module/pageDetails",
            pageDetailsCurrentPage: "page_details_module/currentPage",
            pageDetailsLastPage: "page_details_module/lastPage",
            pageDetailsSearchKeyword:'page_details_module/keyword',
            isPending: "page_details_module/isPending",
        }),
    },
    watch:{
        PageDetails: {
            handler: function (value) {

                this.pageDetailsLoading = false;

                if(this.tempPreviewPageDetails.id != ''){

                    this.pageDetailsList = _.cloneDeep(value);

                    this.pageDetailsList[this.previewForm.index] = this.previewForm;

                }else{

                    this.pageDetailsList = _.cloneDeep(value);

                }

                if(this.isMasterSearched){

                    this.previewSearchedPageDetails(this.pageDetailsList[0],0);

                    this.isMasterSearched = false;

                }

            }, deep: true,
        },
    },
    methods:{
        //infinite scroll start
        handleScrollEvent(event){

            let element = this.$refs.pageDetailsTable;

            let maxScrollTop = element.scrollHeight - element.clientHeight;

            if(element.scrollTop >= maxScrollTop - 100){

                if(this.isPending == false && this.pageDetailsCurrentPage != this.pageDetailsLastPage){

                    this.$store.dispatch("page_details_module/fetchStorePageDetailsRequest",{size: 15, page: this.pageDetailsCurrentPage + 1, loadData: 'more'});
                
                }

            }

        },
        //infinite scroll end

        //OTG update start
        pageDetailsSelection(listedPageDetails){

            this.tempPageDetails = _.cloneDeep(listedPageDetails);

        },
        async handleOTGUpdate(listedPageDetails,index,field){

            if(this.previewForm.id == ''){

                let isRequired = false;

                if(listedPageDetails.title.length == 0 && field == 'title'){

                    this.$notify({
                        type: 'warning',
                        title: 'Alert',
                        message: "The page title field is required.",
                    });

                    isRequired = true;

                }

                if(listedPageDetails.title.length >= 70 && field == 'title'){

                    this.$notify({
                        type: 'warning',
                        title: 'Alert',
                        message: "The page title field may not be greater than 70 characters.",
                    });

                    isRequired = true;

                }

                if(listedPageDetails.description.length == 0 && field == 'description'){

                    this.$notify({
                        type: 'warning',
                        title: 'Alert',
                        message: "The page description field is required.",
                    });

                    isRequired = true;

                }

                if(listedPageDetails.description.length >= 250 && field == 'description'){

                    this.$notify({
                        type: 'warning',
                        title: 'Alert',
                        message: "The page description field may not be greater than 250 characters.",
                    });

                    isRequired = true;

                }

                if(isRequired){

                    this.pageDetailsList[index].title = this.tempPageDetails.title;
                    
                    this.pageDetailsList[index].description = this.tempPageDetails.description;

                }

                let isChanged = false

                if((this.tempPageDetails.title != listedPageDetails.title) || (this.tempPageDetails.description != listedPageDetails.description)){

                    isChanged = true;

                }

                if(isChanged){

                    MessageBox.confirm(
                        'You have unsaved changes, save and proceed?',
                        'Confirm',
                        {
                            confirmButtonText: 'Save',
                            distinguishCancelAndClose: true,
                            cancelButtonText: 'Discard Changes',
                        }
                    ).then(async () => {

                        let formData = new FormData();

                        formData.append('id',listedPageDetails.id);
                        formData.append('title',listedPageDetails.title);
                        formData.append('description',listedPageDetails.description);

                        let loader = Loading.service({
                            text: "The page is being updated. Please wait!",
                            fullscreen: true,
                        });

                        try{
                            let res = await this.$axios.post('/page-details/update',formData);
                            if(res.data.status_code == "1312"){

                                this.$notify({
                                    type: "success",
                                    title: "Success",
                                    message: res.data.message,
                                });

                                let page = res.data.page;

                                let payload = {
                                    index: index,
                                    updatedPageDetails: page,
                                };

                                this.$store.commit("page_details_module/update_page_detail_list",payload);

                            }
                        }catch(error){

                            this.pageDetailsList[index].title = this.tempPageDetails.title;
                    
                            this.pageDetailsList[index].description = this.tempPageDetails.description;

                            this.tempPageDetails = [];

                            if(error.response){

                                if(error.response.data.error.title){

                                    this.$message({
                                        type: "error",
                                        showClose: true,
                                        message: error.response.data.error.title[0],
                                    });

                                }else if(error.response.data.error.description){

                                    this.$message({
                                        type: "error",
                                        showClose: true,
                                        message: error.response.data.error.description[0],
                                    });

                                }else if(error.response.data.error.includes("doesn't support")){

                                    let subscriptionPath = window.location.origin + "/subscription";

                                    this.$message({
                                        type: 'error',
                                        showClose: true,
                                        dangerouslyUseHTMLString: true,
                                        message: error.response.data.error 
                                        + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                                    });

                                }else{

                                    this.$message({
                                        type: "error",
                                        showClose: true,
                                        message: error.response.data.message,
                                    });

                                }

                            }else{

                                this.$message({
                                    type: "error",
                                    showClose: true,
                                    message: error.message,
                                });

                            }

                        }finally{

                            loader.close();

                        }

                    }).catch(() => {

                        this.pageDetailsList[index].title = this.tempPageDetails.title;
                    
                        this.pageDetailsList[index].description = this.tempPageDetails.description;

                        this.tempPageDetails = [];

                        this.$notify({
                            type: "info",
                            title: "Changes Discarded",
                            message: "Changes have been successfully discarded.",
                        });

                    });

                }

            }else{

                if(listedPageDetails.id != this.previewForm.id){
                
                    let isRequired = false;

                    if(listedPageDetails.title.length == 0 && field == 'title'){

                        this.$notify({
                            type: 'warning',
                            title: 'Alert',
                            message: "The page title field is required.",
                        });

                        isRequired = true;

                    }

                    if(listedPageDetails.title.length >= 70 && field == 'title'){

                        this.$notify({
                            type: 'warning',
                            title: 'Alert',
                            message: "The page title field may not be greater than 70 characters.",
                        });

                        isRequired = true;

                    }

                    if(listedPageDetails.description.length == 0 && field == 'description'){

                        this.$notify({
                            type: 'warning',
                            title: 'Alert',
                            message: "The page description field is required.",
                        });

                        isRequired = true;

                    }

                    if(listedPageDetails.description.length >= 250 && field == 'description'){

                        this.$notify({
                            type: 'warning',
                            title: 'Alert',
                            message: "The page description field may not be greater than 250 characters.",
                        });

                        isRequired = true;

                    }

                    if(isRequired){

                        this.pageDetailsList[index].title = this.tempPageDetails.title;
                        
                        this.pageDetailsList[index].description = this.tempPageDetails.description;

                    }

                    let isChanged = false;

                    if((this.tempPageDetails.title != listedPageDetails.title) || (this.tempPageDetails.description != listedPageDetails.description)){

                        isChanged = true;

                    }

                    if(isChanged){

                        MessageBox.confirm(
                            'You have unsaved changes, save and proceed?',
                            'Confirm',
                            {
                                confirmButtonText: 'Save',
                                distinguishCancelAndClose: true,
                                cancelButtonText: 'Discard Changes',
                            }
                        ).then(async () => {

                            let formData = new FormData();

                            formData.append('id',listedPageDetails.id);
                            formData.append('title',listedPageDetails.title);
                            formData.append('description',listedPageDetails.description);

                            let loader = Loading.service({
                                text: "The page is being updated. Please wait!",
                                fullscreen: true,
                            });

                            try{
                                let res = await this.$axios.post('/page-details/update',formData);
                                if(res.data.status_code == "1312"){

                                    this.$notify({
                                        type: "success",
                                        title: "Success",
                                        message: res.data.message,
                                    });

                                    let page = res.data.page;

                                    let payload = {
                                        index: index,
                                        updatedPageDetails: page,
                                    };

                                    this.$store.commit("page_details_module/update_page_detail_list",payload);

                                }
                            }catch(error){

                                this.pageDetailsList[index].title = this.tempPageDetails.title;
                        
                                this.pageDetailsList[index].description = this.tempPageDetails.description;

                                this.tempPageDetails = [];

                                if(error.response){

                                    if(error.response.data.error.title){

                                        this.$message({
                                            type: "error",
                                            showClose: true,
                                            message: error.response.data.error.title[0],
                                        });

                                    }else if(error.response.data.error.description){

                                        this.$message({
                                            type: "error",
                                            showClose: true,
                                            message: error.response.data.error.description[0],
                                        });

                                    }else if(error.response.data.error.includes("doesn't support")){

                                        let subscriptionPath = window.location.origin + "/subscription";

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            dangerouslyUseHTMLString: true,
                                            message: error.response.data.error 
                                            + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                                        });

                                    }else{

                                        this.$message({
                                            type: "error",
                                            showClose: true,
                                            message: error.response.data.message,
                                        });

                                    }

                                    }else{

                                    this.$message({
                                        type: "error",
                                        showClose: true,
                                        message: error.message,
                                    });

                                }

                            }finally{

                                loader.close();

                            }

                        }).catch(() => {

                            this.pageDetailsList[index].title = this.tempPageDetails.title;
                        
                            this.pageDetailsList[index].description = this.tempPageDetails.description;

                            this.tempPageDetails = [];

                            this.$notify({
                                type: "info",
                                title: "Changes Discarded",
                                message: "Changes have been successfully discarded.",
                            });

                        });

                    }
                
                }

            }

        },
        //OTG update end

        //preview form open/close & reset start
        updateFormImageChangeHandle(e){

            this.imageValidates = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }

            if(e.target.files.length > 0){

                const file = e.target.files[0];

                if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
                
                    this.previewForm.image = file;
                    this.previewForm.image_src = URL.createObjectURL(file);

                    this.imageValidations.imgIsRequired = false;

                    if(this.$refs.previewFileInput){

                        this.$refs.previewFileInput.value = ''

                    }

                }else if(file.type.includes('image/')){

                    let uploadedFileType = file.type.slice(6);
                    
                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
                    });

                    // if(!this.previewForm.image){
                    //     this.imageValidations.imgIsRequired = true
                    // }
                    
                    // this.imageValidations.imgInvalidFormat = true

                }else{

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file must be an image.",
                    }); 

                    // if(!this.previewForm.image){
                    //     this.imageValidations.imgIsRequired = true
                    // }

                    // this.imageValidations.isNotImg = true

                }
                
            }

        },
        previewPageDetails(listedPageDetails,i,field,e){

            if(field == 'input'){

                if((e.target.tagName == "TD" || e.target.dataset.open == "true") && !this.tourIsActive){
                    
                    if(!this.pageDetailsSaved && (this.tempPreviewPageDetails.id != listedPageDetails.id)){
                        
                        let tempIndex = this.pageDetailsList.findIndex(x=>x.id == this.tempPreviewPageDetails.id);

                        this.pageDetailsList[tempIndex] = this.tempPreviewPageDetails;

                    }

                    if(this.tempPreviewPageDetails.id != '' && this.tempPreviewPageDetails.id == listedPageDetails.id){

                        let tempIndex = this.pageDetailsList.findIndex(x=>x.id == this.tempPreviewPageDetails.id);
                        
                        this.pageDetailsList[tempIndex] = _.cloneDeep(this.PageDetails[tempIndex]);

                        let pageDetails = _.cloneDeep(this.pageDetailsList[tempIndex]);

                        this.previewForm = this.pageDetailsList[tempIndex];
                        this.previewForm.index = i;

                        this.previewForm.image = null;
                        this.previewForm.image_src = pageDetails.image ? pageDetails.image : null;

                        this.checkedItems = [];

                        this.resetImageValidations();

                        this.tempPreviewPageDetails = _.cloneDeep(listedPageDetails);
                        
                    }else{
                        
                        let pageDetails = _.cloneDeep(listedPageDetails);

                        this.previewForm = listedPageDetails;
                        this.previewForm.index = i;

                        this.previewForm.image_src = pageDetails.image ? pageDetails.image : null;
                        this.previewForm.image = null;

                        this.checkedItems = [];

                        this.resetImageValidations();

                        this.tempPreviewPageDetails = _.cloneDeep(pageDetails)

                    }

                }
            
            }else{

                if(!this.pageDetailsSaved && (this.tempPreviewPageDetails.id != listedPageDetails.id)){
                        
                    let tempIndex = this.pageDetailsList.findIndex(x=>x.id == this.tempPreviewPageDetails.id);

                    this.pageDetailsList[tempIndex] = this.tempPreviewPageDetails;

                }

                if(this.tempPreviewPageDetails.id != '' && this.tempPreviewPageDetails.id == listedPageDetails.id){

                    let tempIndex = this.pageDetailsList.findIndex(x=>x.id == this.tempPreviewPageDetails.id);
                    
                    this.pageDetailsList[tempIndex] = _.cloneDeep(this.PageDetails[tempIndex]);

                    let pageDetails = _.cloneDeep(this.pageDetailsList[tempIndex]);

                    this.previewForm = this.pageDetailsList[tempIndex];
                    this.previewForm.index = i;

                    this.previewForm.image = null;
                    this.previewForm.image_src = pageDetails.image ? pageDetails.image : null;

                    this.checkedItems = [];

                    this.resetImageValidations();

                    this.tempPreviewPageDetails = _.cloneDeep(listedPageDetails);
                    
                }else{
                    
                    let pageDetails = _.cloneDeep(listedPageDetails);

                    this.previewForm = listedPageDetails;
                    this.previewForm.index = i;

                    this.previewForm.image_src = pageDetails.image ? pageDetails.image : null;
                    this.previewForm.image = null;

                    this.checkedItems = [];

                    this.resetImageValidations();

                    this.tempPreviewPageDetails = _.cloneDeep(pageDetails);

                }

            }

        },
        previewSearchedPageDetails(listedPageDetails,i){

            let pageDetails = _.cloneDeep(listedPageDetails);

            this.previewForm = listedPageDetails;
            this.previewForm.index = i;

            this.previewForm.image_src = pageDetails.image ? pageDetails.image : null;
            this.previewForm.image = null;

            this.checkedItems = [];

            this.resetImageValidations();

            this.tempPreviewPageDetails = _.cloneDeep(pageDetails);

        },
        closePreview(){
        
            if(!this.pageDetailsSaved){

                let tempIndex = this.pageDetailsList.findIndex(x=>x.id == this.tempPreviewPageDetails.id);

                this.pageDetailsList[tempIndex] = this.tempPreviewPageDetails;
                
            }

            this.previewForm = _.cloneDeep(this.defaultPreviewForm);

            this.tempPreviewPageDetails = {
                id:"",
                page_name:"",
                page_slug:"",
                title:"",
                description:"",
                image: null,
                image_src: "",
            };

            this.pageDetailsSaved = false;

        },
        resetPreview(){
            
            this.previewForm = _.cloneDeep(this.defaultPreviewForm);

            this.tempPreviewPageDetails = {
                id:"",
                page_name:"",
                page_slug:"",
                title:"",
                description:"",
                image: null,
                image_src: "",
            };

            this.resetImageValidations();
            
            this.pageDetailsSaved = false;

        },
        //preview form open/close & reset end

        //update page details start
        validate(){

            this.$refs.pageDetailsFormObserver.validate().then(success => {

                if(!success){

                    const errors = Object.entries(this.$refs.pageDetailsFormObserver.errors)
                    .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

                    this.$refs.pageDetailsFormObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

                }else{

                    this.resetImageValidations();

                    this.pageDetailsUpdateHandle();

                }

            });

        },
        async pageDetailsUpdateHandle(){

            let formData = new FormData();

            formData.append('id',this.previewForm.id);
            formData.append('title',this.previewForm.title);
            formData.append('description',this.previewForm.description);

            if(this.previewForm.image){

                formData.append('image',this.previewForm.image);

            }
            
            this.pageDetailsIsSaving = true
            try{
                let res = await this.$axios.post('/page-details/update',formData);
                if(res.data.status_code == "1312"){

                    this.$notify({
                        type: "success",
                        title: "Success",
                        message: res.data.message,
                    });

                    let page = res.data.page;

                    let payload = {
                        index: this.previewForm.index,
                        updatedPageDetails: page,
                    };

                    this.$store.commit("page_details_module/update_page_detail_list",payload);

                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                    this.$store.commit('gallery_module/CLEAR_MODULE');

                    this.pageDetailsSaved = true;

                    this.resetPreview();

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.title){

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.response.data.error.title[0],
                        });

                    }else if(error.response.data.error.description){

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.response.data.error.description[0],
                        });

                    }else if(error.response.data.error.includes("doesn't support")){

                        let subscriptionPath = window.location.origin + "/subscription";

                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error 
                            + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                        });

                    }else{

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: "error",
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.pageDetailsIsSaving = false;

            }

        },
        //update page details end

        //search page details start
        searchPageDetail(){

            this.closePreview();

            this.pageDetailsLoading = true;

            clearTimeout(this.timer);

            this.timer = setTimeout(() => {

                if(this.searchedItem.length > 0){

                    let payload = {
                        keyword: this.searchedItem,
                    };

                    this.$store.commit("page_details_module/update_searched_keyword",payload);
                    this.$store.dispatch("page_details_module/fetchStorePageDetailsRequest",{size: 10, page: 1, loadData: 'new'});

                }else{

                    this.$store.commit("page_details_module/remove_searched_keyword");
                    this.$store.dispatch("page_details_module/fetchStorePageDetailsRequest",{size: 10, page: 1, loadData: 'new'});

                }
                
            }, 500);

        },
        clearSearch(){

            this.searchedItem = ''

            this.searchPageDetail();

        },
        //search page details end

        //other methods start
        resetImageValidations(){
            
            this.imageValidations = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }

        },
        generateUrl(listedPageDetail){
            
            if(listedPageDetail.image){

                if(typeof(listedPageDetail.image) == "string"){

                    return this.base_image_url + this.settings.store_id + '/page_details/' + listedPageDetail.image;

                }else{

                    return listedPageDetail.image_src;

                }
                
            }else if(listedPageDetail.image_src){

                return this.base_image_url + this.settings.store_id + '/page_details/' + listedPageDetail.image_src;

            }

        },
        expand(){

            this.$refs.viewInputForm.classList.toggle('fullWidthSlide');

        },
        keyEvent(e){

            if(e.key == 'Escape'){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.$refs.viewInputForm.classList.remove('fullWidthSlide');

                }else if(this.$refs.viewInputForm.classList.contains('activeRow')){

                    this.closePreview();

                }

            }

        },
        //other methods start

        //tour methods start
        startPageDetailsTour(){

            this.$tours.pageDetailsTour.start()

            this.tourIsActive = true;

            if(this.previewForm.id != ''){

                this.closePreview();

            }

        },
        handleNextStep(currentStep){

            if(currentStep + 1 == 7){

                this.tempPreviewPageDetails = _.cloneDeep(this.pageDetailsList[0]);

                this.previewForm = this.pageDetailsList[0];
                this.previewForm.index = 0;

                this.previewForm.image_src = this.pageDetailsList[0].image ? this.pageDetailsList[0].image : null;
                this.previewForm.image = null;

                this.checkedItems = [];

                this.resetImageValidations();

                document.querySelector(".tableActionBtns").style.position = "static";

                document.querySelector(".productSlideBox").style.overflowY  = "hidden";

            }

            if(currentStep >= 12){

                document.querySelector(".tableActionBtns").style.position = "sticky";

            }

            if(currentStep + 1 == 16){

                this.expand();

            }

            if(currentStep + 1 == 17){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                } 

            }

        },
        handlePreviousStep(currentStep){

            if(currentStep == 7){

                document.querySelector(".tableActionBtns").style.position = "sticky";

                document.querySelector(".productSlideBox").style.overflowY  = "auto";

                this.resetPreview();

            }

            if(currentStep <= 13){

                document.querySelector(".tableActionBtns").style.position = "static";

            }

            if(currentStep == 17){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                }

            }

            if(currentStep == 16){

                this.expand();

            }

        },
        handleTourStop(){

            document.querySelector(".tableActionBtns").style.position = "sticky";

            document.querySelector(".productSlideBox").style.overflowY  = "auto";

            this.closePreview();

            this.tourIsActive = false;

        },
        handleTourFinish(){

            document.querySelector(".tableActionBtns").style.position = "sticky";

            document.querySelector(".productSlideBox").style.overflowY  = "auto";

            this.closePreview();

            this.tourIsActive = false;

        },
        handleTourSkip(){

            document.querySelector(".tableActionBtns").style.position = "sticky";

            document.querySelector(".productSlideBox").style.overflowY  = "auto";

            this.closePreview();

            this.tourIsActive = false;

        },
        //tour methods end

        //image loading error handling
        handleImageError(e){

            e.target.src = '/images/image_not_found.png'

        },

    },
    mounted(){

        this.$refs.pageDetailsTable.addEventListener('scroll',this.handleScrollEvent);

        window.addEventListener('keydown',this.keyEvent);

        if(this.$route.query.search){
      
            this.searchedItem = this.$route.query.search;

            if(this.$route.query.preview == 1){

                this.isMasterSearched = true;

            }

            this.$router.replace({'query': null});


            this.searchPageDetail();

        }

    },
    beforeMount(){

        try{

            if((!this.PageDetails || this.PageDetails.length == 0) && this.pageDetailsSearchKeyword.length == 0){

                this.pageDetailsLoading = true;

                this.$store.dispatch("page_details_module/fetchStorePageDetailsRequest",{size: 15, page: 1, loadData: 'new'});

            }else{

                this.pageDetailsList = _.cloneDeep(this.PageDetails);

                this.pageDetailsLoading = false;

            }

            if(this.pageDetailsSearchKeyword.length > 0){

                this.searchedItem = this.pageDetailsSearchKeyword;

            }

        }catch(error){

            this.pageDetailsLoading = false;

            this.$message({
                type: "error",
                showClose: true,
                message: error.response ? error.response.data.message : error.message,
            });

        }

    },
    beforeDestroy(){

        window.removeEventListener("keydown", this.keyEvent);

    },

}
window.myFunction = function (event) {
    // reset all menu items
    document.querySelectorAll(".sectionTabsLink ul li a.active")
    .forEach(function (item) {
        item.classList.remove("active");
    });
    // mark as active selected menu item
    event.target.classList.add("active");
};
</script>

<style>
    .category-tab-img {
        height: 400px;
        width: 400px;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        overflow: hidden;
        position: relative;
    }
    .category-tab-img:hover {
        border-color: #409EFF;
    }
    .category-tab-img img{
        height: 100%;
        width: 100%;
    }
    .category-tab-img svg{
        position: absolute;
        width: 50px;
        top: 43%;
        left: 0;
        right: 0;
        margin: 0 auto;
        fill: #d9d9d9;
    }
    .category-tab-img:hover svg{
        fill: #409EFF;
    }
</style>